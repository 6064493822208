.container {
    display: flex;
    justify-content: space-between;
    /* background-color: #f5f5f5; */
    padding: 10px;
    font-family: Arial, sans-serif;
  }

.inflow, .outflow {
    text-align: left;
}

.section-title {
    font-size: 18px;
    color: #333;
    margin-bottom: 5px;
}

.section-value {
    font-size: 24px;
    color: #000;
}

.section-subvalue {
    font-size: 16px;
    color: #666;
}