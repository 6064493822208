/* BugReportForm.css */
.bug-report-form {
    background-color: #f8f8f8;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    height: 600px;
    margin: auto;
}

.report-info {
    margin-bottom: 20px;
}

.report-info p {
    font-size: 0.9rem;
    color: #333;
    margin: 5px 0;
    padding: 10px;
}

.description-field {
    width: 98%;
    height: 400px;
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    resize: vertical;
}

.submit-button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1rem;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 4px;
}

.submit-button:hover {
    background-color: #45a049;
}
