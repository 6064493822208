.report-container {
    display: flex;  
  }
  
.table-of-contents {
    position: sticky;
    top: 20px;
    height: calc(100vh - 40px);
    overflow-y: auto;
    padding: 20px;
    background-color: #f5f5f5;
    width: 250px;
}

.table-of-contents li a {
    cursor: pointer;
}

.report-content {
    flex: 1;
    padding: 20px;
    text-align: center;
}


/* Styles for both Table of Contents and Jump to Section items */
.table-of-contents li a {
  cursor: pointer;
  color: #333;
  text-decoration: none;
  transition: all 0.3s ease;
  display: block;
  padding: 5px 10px;
  border-radius: 4px;
}

/* Highlight effect on hover */
.table-of-contents li a:hover {
  color: #007bff;
  background-color: #e9ecef;
}

/* Active/current section highlight */
.table-of-contents li a.active {
  color: #007bff;
  background-color: #e9ecef;
  font-weight: bold;
}

/* Additional styles for better spacing and appearance */
.table-of-contents ul {
  padding-left: 5px;
  list-style-type: none;
  border-radius: 10px;
}

.table-of-contents li {
  margin-bottom: 5px;
}