/* Footer.css */
.footer {
    background-color: #f8f9fa; /* Light gray background, you can change it */
    padding: 1rem 0;
    text-align: center;
    border-top: 1px solid #ddd; /* If you want a border on top of the footer */
}

.footer .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
}
