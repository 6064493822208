.email-verification-page-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 89vh; /* Full viewport height */
    /* background-color: #f5f5f5; Optional: background color for the page */
}

.email-verification-container {
    max-width: 500px;
    margin: auto;
    text-align: center;
    padding: 100px;
    /* border: 1px solid #ddd;
    border-radius: 8px; */
    border: 2px solid #ddd; /* Bordered box */
    border-radius: 60px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: adds a subtle shadow */
    background-color: #ffffff; /* Optional: background color for the form */
}

.email-verification-container .digit-input {
    margin: 10px;
    padding: 8px;
    width: 40px;
    height: 50px;
    text-align: center;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 20px;
}

.email-verification-container button {
    /* padding: 8px 16px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    display: block;
    margin: 10px auto 0; */

    padding: 0.5rem 1rem;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    height: 60px;
    width: 120px;
    margin: 10px auto 0;
    font-size: 20px;
}

.email-verification-container button:hover {
    background-color: #45a049;
}
