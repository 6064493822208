.list-container {
    width: 100%;
    border-bottom: 1px solid #ccc;
    font-family: Arial, sans-serif;
    background-color: #f2f2f2;
}

.list-group, .sublist-group {
    border-bottom: 1px solid #ccc;
    
}

.sublist-group {
    padding-left: 20px;
}

.list-title, .sublist-title {
    cursor: pointer;
    user-select: none;
    padding: 8px 12px;
    display: flex;
    align-items: center;
    font-size: 16px;
}

.list-item {
    padding: 8px 12px;
    padding-left: 48px; /* Adjusted indentation */
}

.chevron {
    display: inline-block;
    margin-right: 8px;
}

.sublist-container {
    background: #f9f9f9;
}

.sublist {
    list-style-type: disc;
    list-style-position: inside;
    margin: 0;
    padding: 0;
}

/* Media query for responsive adjustment */
@media (max-width: 600px) {
    .list-title, .sublist-title {
        font-size: 14px;
    }
}
  