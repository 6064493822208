.checkbox-group-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
    /* margin-left: 10px; */
    margin-bottom: 0px;
    justify-content: center; 
    width: 100%;
}
  
.checkbox-container {
    display: flex;
    align-items: center;
}

.checkbox-container label {
    margin-left: 5px;
}

.selected-option-text {
    margin-top: 10px;
}
  