.edit-collection {
    text-align: center;
    max-width: 1200px;
    margin: auto;
    /* padding: 20px; */
}

.back-button {
    font-weight: bold;
    font-size: 16px;
    color: white; 
    padding: 12px;
    margin-top: 10px; 
    background-color: #07849dff;
    border-radius: 10px;
    border: 1px solid white;
    text-decoration: none;
    position: absolute;
    top: 100px;
    left: 100px;
}

.back-button:hover{
    color: black;
    background-color: white;
    border: 1px solid black;
    cursor: pointer;
}

.title-input {
    margin: 20px 0;
    padding: 10px;
    width: 50%;
    font-size: 1rem;
}

.access-list-textarea {
    margin: 20px 0;
    padding: 10px;
    width: 50%; /* You can adjust the width as needed */
    height: 100px; /* Set a fixed height or use min-height to specify the minimum height */
    font-size: 1rem;
    resize: vertical; /* Allow only vertical resizing */
    overflow-y: auto; /* Add scrollbar when content overflows vertically */
}

.content-edit-collection {
    display: flex;
    justify-content: center;
    align-items: start;
    gap: 20px;
}

.section-edit-collection {
    flex: 1;
    padding: 20px;
    /* border: 1px solid #ccc; */
    min-height: 500px;
    min-width: 700px;
}

.divider {
    border: 1px dotted black;
    min-height: 55vh;
    height: 100%;
}

.save-changes-button {
    font-weight: bold;
    font-size: 16px;
    color: white; 
    padding: 12px;
    margin-top: 10px; 
    background-color: #07849dff;
    border-radius: 10px;
    border: 1px solid white;
    text-decoration: none;
}

.save-changes-button:hover{
    color: black;
    background-color: white;
    border: 1px solid black;
    cursor: pointer;
}

.add-to-collection-button {
    font-weight: bold;
    font-size: 16px;
    color: white; 
    padding: 12px;
    margin-top: 10px; 
    background-color: #07849dff;
    border-radius: 10px;
    border: 1px solid white;
    text-decoration: none;
}

.add-to-collection-button:hover{
    color: black;
    background-color: white;
    border: 1px solid black;
    cursor: pointer;
}

.search-button {
    font-weight: bold;
    font-size: 16px;
    color: white; 
    padding: 12px;
    margin-top: 10px; 
    background-color: #07849dff;
    border-radius: 10px;
    border: 1px solid white;
    text-decoration: none;
}

.search-button:hover{
    color: black;
    background-color: white;
    border: 1px solid black;
    cursor: pointer;
}

.clear-button {
    font-weight: bold;
    font-size: 16px;
    color: white; 
    padding: 12px;
    margin-top: 10px; 
    background-color: #07849dff;
    border-radius: 10px;
    border: 1px solid white;
    text-decoration: none;
}

.clear-button:hover{
    color: black;
    background-color: white;
    border: 1px solid black;
    cursor: pointer;
}

.company-table {
    max-height: 450px; /* Adjust based on your needs */
    overflow-y: scroll;
    /* border: 1px solid black; */
    
}

.company-table table {
    width: 100%;
    border-collapse: collapse;
}

.company-table th,
.company-table td {
    border: 1px solid #ccc;
    padding: 5.2px;
    text-align: center;
}

.company-table th {
    padding: 8px;
    background-color: #f2f2f2;
}

.input-container {
    display: flex;
    justify-content: space-around; /* Adjust space between the input fields */
    align-items: flex-start; /* Align items to the top */
    margin-bottom: 20px;
}

.input-group {
    display: flex;
    /* flex-direction: column; */
    align-items: center;
}

.input-label {
    margin-right: 10px; /* Add some space between the label and the input */
    white-space: nowrap; /* Prevent the label from wrapping */
}

.title-input, .access-list {
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-left: 10px;
}

.title-input {
    height: 20px; /* Fixed height for the input */
    width: 200px; /* Fixed width for the input */
}

.access-list {
    min-height: 100px; /* Minimum height for the textarea */
    width: 400px; /* Fixed width for the textarea */
    resize: vertical; /* Allow only vertical resizing */
    overflow-y: auto; /* Add scrollbar when content overflows vertically */
}