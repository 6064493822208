.group {
    padding: 10px;
    background-color: #f2f2f2;
    border: 1px solid #ddd;
    margin-top: 5px;
    cursor: pointer;
}
  
.title-list {
    /* list-style-type: none; /* Removes default bullet points */
    padding-left: 20px; /* Adjusts padding to your liking */
}

.title-list li {
    /* Styles for list items */
    padding: 5px 0;
    border-bottom: 1px solid #ccc; /* If you want a separator line */
}

.group-name::after {
    /* content: ' (Click to expand)'; */
    font-size: 12px;
    color: #888;
}

.group-name.expanded::after {
    content: ' (Click to collapse)';
}

.chevron {
    display: inline-block;
    margin-left: 10px;
    transition: transform 0.2s; /* Smooth transition for rotation */
}

.group-name .chevron {
    /* Rotate the chevron when the list is open */
    transform: rotate(360deg);
}
