/* Style for the dropdown */
.dropdown {
    position: relative;
    display: inline-block;
    padding-top: 5px;
  }
  
  /* .dropbtn {
    background-color: teal;
    color: white;
    padding: 16px;
    font-size: 16px;
    border: none;
    cursor: pointer;
    border-radius: 20px;
  } */

  .dropbtn {
    font-weight: bold;
    font-size: 16px;
    color: white; 
    padding: 10px;
    margin-top: 10px; 
    background-color: #07849dff;
    border-radius: 10px;
    border: 0.1px solid white;
    text-decoration: none;
    /* transition: transform 0.1s ease; */
  }
  
  .dropbtn:hover {
    /* transform: scale(1.1); */
    /* background-color: #a63a3a; */
    color: black;
    background-color: white;
    border: 1px solid black;
    cursor: pointer;
  }
  
  .dropbtn:active {
    /* transform: scale(0.9); */
    /* background-color: #a63a3a; */
    color: black;
    background-color: white;
    border: 1px solid black;
  }
  
  /* Dropdown content */
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: white;
    min-width: 160px;
    overflow: auto; /* Enables scrolling */
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    max-height: 150px; /* Fixed height */
  }
  
  /* Links inside the dropdown */
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    cursor: pointer;
  }
  
  /* Show the dropdown content on hover */
  .dropdown:hover .dropdown-content {
    display: block;
    border-radius: 10px;
  }
  
  /* Change color of dropdown links on hover */
  .dropdown-content a:hover {
    background-color: lightgray;
  }
  
  /* Change the background color of the dropdown button when the dropdown content is shown */
  /* .dropdown:hover .dropbtn {
    background-color: turquoise;
  } */
  